import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["message", "stack", "cause"];
import { isLocalService, shouldUseLocalBackend } from '../localRequest/localRequestUtils';
export class ChirpInternalError extends Error {}
export class ChirpError extends Error {}
export function getAvroGateway(auth, timeout, isLocal = false) {
  if (isLocal) {
    return getLocalAvroGateway();
  }
  if (auth === 'app' && timeout && timeout > 5000) {
    return `/chirp-frontend-app-slow/v1/gateway`;
  }
  return `/chirp-frontend-${auth}/v1/gateway`;
}
export function getLocalAvroGateway() {
  return `https://local.hubteamqa.com/local-proxy/gateway`;
}

/**
 * @deprecated This was used only from code generation which has been removed. This will be removed when usage moves to 0
 */

/**
 * @deprecated This was used only from code generation which has been removed. This will be removed when usage moves to 0
 */

/**
 * @deprecated This was used only from code generation which has been removed. This will be removed when usage moves to 0
 */

const defaultTag = Symbol('defaultTag');
/**
 *
 * Represents an object property marked as `@Default` in Java.
 * These properties are always present on responses, but are optional on requests (empty values will fall back to the default specified on the model).
 * To be able to assign a value to this field, wrap the type in Requestify
 */

function spreadPropertiesIntoError(error, properties) {
  const spreadableProperties = _objectWithoutPropertiesLoose(properties, _excluded);
  return Object.assign(error, spreadableProperties);
}
export function parseRejectingChirpErrors(response) {
  if (response.type === 'data') {
    return response.data;
  } else if (response.type === 'rpcError') {
    const error = new ChirpError('CHIRP RPC failed with a known error', {
      cause: response.rpcError
    });
    throw spreadPropertiesIntoError(error, response.rpcError);
  } else {
    const error = new ChirpInternalError('CHIRP RPC failed with a CHIRP internal error', {
      cause: response.internalError
    });
    throw spreadPropertiesIntoError(error, response.internalError);
  }
}
export function parseIntoUnion(response) {
  if (response.type === 'internalError') {
    const error = new ChirpInternalError('CHIRP RPC failed with a CHIRP internal error', {
      cause: response.internalError
    });
    throw spreadPropertiesIntoError(error, response.internalError);
  }
  return response;
}
export async function makeChirpAvroCall({
  hubHttpClient,
  details,
  responseParser,
  inputs,
  alternateData
}) {
  const {
    request,
    timeout
  } = inputs !== null && inputs !== void 0 ? inputs : {
    request: {}
  };
  const {
    auth,
    serviceName,
    rpcName,
    fingerprint
  } = details;
  const syncStackError = new Error(`CHIRP RPC failed for ${rpcName}`);
  const clientTimeout = timeout !== null && timeout !== void 0 ? timeout : 5000;
  const shouldMakeLocalRequest = shouldUseLocalBackend() && (await isLocalService(serviceName));
  const data = alternateData !== null && alternateData !== void 0 ? alternateData : hubHttpClient.post(`${getAvroGateway(auth, clientTimeout, shouldMakeLocalRequest)}/${serviceName}/${rpcName}`, {
    data: request,
    headers: {
      'x-hs-fingerprint': fingerprint
    },
    timeout: clientTimeout,
    query: {
      clienttimeout: String(clientTimeout)
    }
  }).catch(errorResponse => {
    if (errorResponse.status !== 0 && 'responseJSON' in errorResponse) {
      return errorResponse.responseJSON;
    }
    throw errorResponse;
  });
  return data.then(response => responseParser(response)).catch(error => {
    syncStackError.cause = error;
    throw syncStackError;
  });
}
export function formatChirpAvroMSWSuccessResponse(mockResponse) {
  return {
    type: 'data',
    data: mockResponse
  };
}
export function formatChirpAvroMSWUserErrorResponse(mockErrorResponse) {
  return {
    type: 'rpcError',
    rpcError: mockErrorResponse
  };
}
export function formatChirpAvroMSWInternalErrorResponse(mockErrorResponse) {
  return {
    type: 'internalError',
    internalError: mockErrorResponse
  };
}